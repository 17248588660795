// Copyright (C) 2023 Visual Intelligence LP. All rights reserved.
//
import * as React from 'react';
import { withStyles } from "tss-react/mui";
import { Tooltip, Button, Grid } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { observer } from 'mobx-react';
import { makeObservable, observable, action, runInAction, computed } from 'mobx';
import container from '@core/di';
import AuthStore from '@core/stores/auth';
import { HeaderConfig } from '@modules/Private/components/Header';
import Flex from '@shared/components/Flex';
import Field from '@shared/components/Form/components/Field';
import Loading from '@shared/components/Loading';
import Select, { SerializerType } from '@shared/components/Select';
import { showNotification, NotificationType } from '@shared/components/Notification';
import { JOB_REQUEST_FILTERS, PRIORITY_ENUM } from '@shared/constants/job-request';
import { ORDER_MAX_COUNT, ORDER_FIELDS } from '@shared/constants/order';
import { SortingType } from '@shared/constants/services';
import { WA_ORDER_FILTERS, WA_SELECTED_ORDER_ID } from '@shared/constants/sp-sheets';
import { FilterItems } from '@shared/models/jobrequesttimelines';
import { AssetsStore, OrdersStore } from '@shared/stores';
import { ListRequestParams } from '@shared/types/services';
import { readFrLocalStorage, CapFirstLetter, errObj2Str, clearPersistData, findKeyByValue, constructionType2name } from '@shared/utils/common';
import { RefreshFlagContext } from '@modules/Private/Private';
import Sheets from './Sheets/Sheets';
import Order from '@shared/models/order';
import { EquipmentDTO, UpdateEquipmentDTO, WASummary } from '@shared/models/windarea';
import { EQUIPMENT_STATUS_LABEL } from '@shared/constants/equipment';
import DBFilter from '@modules/Private/components/DBFilter/DBFilter';

// prop interface for the main class.
export interface WindAreaProps {
  className?: string;
  setHeaderConfig: (config: HeaderConfig) => any;
}

// Class to display the WindArea page.
@observer
class WindArea extends React.Component<WindAreaProps, {loading: boolean}> {
  // These objs have fixed fields. Not sure why SInformationDTO2 is used here.
  equipmentsInfo: Array<EquipmentDTO>;         // List of sites for the current order
  summaryInfo: Array<WASummary>;               // List of site summary records for the current order
  private equipmentChanged: boolean;           // Flag indicating the equipment array has changed

  towerNames: Array<string>;                      // list of possible tower names.
  towerId2Names: {[key: number]: string};       // list of possible tower names.
  equipmentTypes: {[key: number]: string};        // list of possible equipment types.

  // stateful vars for rerendering.
  @observable _noData: boolean;                                // Clear when order has been loaded to trigger a render.
  @observable _orders: {id: number, label: string}[];          // List of order id and name.
  @observable _selectedOrderId: number;                        // The currently selected orderId.
  filterConfig: Array<FilterItems>;               // Array to keep current filter settings.

  // Store objs to query for data from portal-api.
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private assetsStore = container.get<AssetsStore>(AssetsStore.diToken);
  private ordersStore = container.get<OrdersStore>(OrdersStore.diToken);

  private curOrder: Order;                              // Order info for the currently selected orderId.

  private userId: string;

  private priority2Status: {[id: number]: string} = {};

  // Container for the Sheet components.
  // Note: This class could be merged with the top component in the Map branch.
  constructor(props: WindAreaProps) {
    super(props);

    this.state = {loading: false};

    // Set page header.
    props.setHeaderConfig({
      moduleName: 'Wind area calculations',
    });

    // Get the Id of the login.
    const { user } = this.authStore;
    this.userId = user.id.toString();

    this.equipmentsInfo = [];
    this.summaryInfo = [];
    this.equipmentChanged = false;

    this.towerNames = [];
    this.equipmentTypes = [];

    this._noData = false;

    const orderId = Number(readFrLocalStorage(this.mkUserKey(WA_SELECTED_ORDER_ID)));
    this._selectedOrderId = orderId ? orderId : -1;

    this.initFilterConfig();
    // Use saved filter config if available.
    const curFilters = readFrLocalStorage(this.mkUserKey(WA_ORDER_FILTERS));
    if(curFilters != undefined && curFilters.length > 0)
    {
      const orderFilters = JSON.parse(curFilters);
      //console.log("*** current filter:", orderFilters);
      
      if (orderFilters) this.filterConfig = orderFilters;
    }

    // Reverse the Enum to lookup name from priority number.  Obsolete???
    Object.keys(PRIORITY_ENUM).forEach(k => {
      if(isNaN(Number(k)) && !isNaN(Number(PRIORITY_ENUM[k]))) {
        this.priority2Status[Number(PRIORITY_ENUM[k])] = CapFirstLetter(k).replace(/_/g, ' ');
      }
    });
    this._orders = [];
    makeObservable(this);
  }

  @computed get noData() {
    return this._noData;
  }

  @computed get orders() {
    return this._orders;
  }

  @computed get selectedOrderId() {
    return this._selectedOrderId;
  }

  // Create a user-specific key by append the userId to the key.
  private mkUserKey(keyStr: string): string
  {
    return `UID_${this.userId}_${keyStr}`;
  }

    // return the component array for the top row: 'Order' selection, 'Filters' menu, and 'Refresh' link.
  private get fields() {
    const classes = withStyles.getClasses(this.props);
    //console.log("**** in fields:", this.orders);

    const isEmptyOrder = this.orders.length == 0 || this.selectedOrderId == -1;

    // Items should be in order staff, platform, asset, priorities, tag.  If not, will need to do some sorting here.
    const filterSelections = this.filterConfig.map((item) => 
        ((item.ids && item.ids.length > 0) || item.keywords) ? 1 : 0);  // Set flag to indicate if a filter is active (contains ids or keywords).
    
    // Setup the component array.
    const _fields = [
      { // Order selection dropdown.
        label: 'Order',
        id: 0,
        component: (
          <Select
            classes={{ root: classes.selectBox }}
            serializerType={isEmptyOrder ? SerializerType.string : SerializerType.number}
            options={this.orders}
            placeholder="Select order"
            value={isEmptyOrder ? '' : this.selectedOrderId}
            testAttributeArgs={{
              module: 'WindArea',
              elementDescription: 'order',
            }}
            onChange={this.handleOrderChange.bind(this)}
          />
        )
      },
      { // Filter menu. There should be no label.
        label: '',
        id: 1,
        component: (
          <div>
            <DBFilter
              orderId={this.selectedOrderId}
              filterConfig={this.filterConfig}
              filterSelections={filterSelections}
              doFilter={this.handleFilterJobRequests.bind(this)}
              isProjectManager={this.authStore.user.isProjectManager}
            />
          </div>
        )
      },
      { // Refresh link. There should be no label.
        label: '',
        id: 2,
        component: (
          <Tooltip title='Reload data and refresh page'>
            <Button
              classes={{root: classes.refreshButton}}
              endIcon={<RefreshIcon />}
              onClick={this.handleRefresh.bind(this)}
              size="large"
              color="primary"
            >
              Refresh
            </Button>
          </Tooltip>
        )
      }
    ];

    return _fields;
  }

  // Load sites when an order Id is selected.
  @action handleOrderChange = async (orderId: number) => {
    // e.persist();
    //console.log("Selected orderId (param) = ", selectedOrderId);
    //console.log("Selected orderId (property) = ", this.selectedOrderId);
    this._selectedOrderId = orderId;
    clearPersistData();
    await this.handleRefresh();
  }

  // Call refresh to reload order info after filters are set by user.
  handleFilterJobRequests = async (filterItems: FilterItems) => {
    console.log("*** in handleFilterJobRequest:", filterItems);
    const { type } = filterItems;

    // Fetch new data if a valid filter is set.
    if(Object.values(JOB_REQUEST_FILTERS).includes(type)) {

      // Search for the filter and update its content.
      this.filterConfig.find((item, idx) => {
        if (item.type === type) {
          this.filterConfig[idx] = filterItems;
          
          // Copy settings from 'assets' to 'assetsByMap'
          if (item.type === JOB_REQUEST_FILTERS.assets) {
            const assetsByMapFilterItemsIdx = this.filterConfig.findIndex(x => x.type === JOB_REQUEST_FILTERS.assetsByMap);
            if (assetsByMapFilterItemsIdx !== -1)
              this.filterConfig[assetsByMapFilterItemsIdx].ids = filterItems.ids;
          }
          
          return true; // stop searching
        }
      });

      await this.handleRefresh();
    }
  }

  // Event handler for clicking on the Refresh button.
  // It can also be called by child components to refresh the page. 
  // The function reloads order data from portal-api.
  handleRefresh = async () => {
    console.log("*** Handle refresh request from sub component.");
    if(this.selectedOrderId > -1) {
      await this.loadEquipmentsInfo();

      //await this.syncJobRequests();
    }
  }

  // Triggered after page is loaded.  Calls that need 'async' should be here not in constructor().
  async componentDidMount() {  
    //console.log('*** in WindArea_componentDidMount:');

    await this.setupOrderSelectionBox(); // Get list of orders

    // After a login, the page is loaded with the Order selection box showing the order from last time,
    // but 'order' data is not loaded.  Load it now.
    if (this.selectedOrderId != -1) {
      await this.handleRefresh();
    }

    // this.syncId = setInterval(() => { this.syncJobRequests();}, // Run sync jobReqs
    //                           SYNC_INTERVAL_MS);                // every sync interval.
  }

  // Init an "empty" filter config array.
  initFilterConfig() {
    // Init filter config.  Changes in ids/order here must be synced to DBFilter::showFilters() and menuItems, and job-request.ts::JOB_REQUEST_FILTERS
    this.filterConfig = [
      { type: JOB_REQUEST_FILTERS.staff, // 0
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.assets, // 1
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.task, // 2
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.priority, // 3
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.tag1, // 4
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.flag, // 5
        ids: [],
        keywords: ''},
      // { type: JOB_REQUEST_FILTERS.platforms, // 6
      //   ids: [],
      //   keywords: ''},
      { type: JOB_REQUEST_FILTERS.planned, // 6
        ids: [],
        keywords: ''},
      { type: JOB_REQUEST_FILTERS.assetsByMap, // 7
        ids: [],
        keywords: ''}
    ];
  }

  // Show the top row with 'Order' selection box, 'Filters' dropdown box, and 'Refresh' link
  showFlex = () => {
    const classes = withStyles.getClasses(this.props);

    //console.log("*** fields:", this.fields);

    return(
      <Flex container>
        {this.fields.map((field, idx) => (
          <Grid key={field.id}>
            <Field classes={ idx == 0 ? { root: classes.selectOrder } : { } } {...field} key={field.id} />
          </Grid>
        ))}
      </Flex>
    )
  }

  private loadOrder = async () => {
    // Check if the filters contain any ids or keywords.
      const filterOn = this.filterConfig.reduce((sum, item2) => sum || 
                                                            ((item2.ids === undefined || item2.ids.length == 0) ? false : true) ||  // has Id's
                                                            (item2.keywords ? true: false),                                         // has keywords
                                                            false);

      if(filterOn) {  // Load order info with filters.
        const filtering = {
          byCategory: this.filterConfig,
        };
  
        // setup params to pass the filtering keys.
        const params: ListRequestParams = {
          pagination: {
            page: 1,
            pageSize: 1,      
          },
          filtering: filtering,
        };

        // Pull data from portal-api.                               Take less than the unfiltered query below.
        // console.log('*** getOrderFullInfoFiltered');
        this.curOrder = await this.ordersStore.getOrderFullInfoFiltered(this.selectedOrderId, params)
      }
      else { // Load order info without filters.
        // Pull order info from portal-api.                         Take around 1.5 sec on NM's comp.
        // console.log('*** ordersStore.get');
        this.curOrder = await this.ordersStore.getById(this.selectedOrderId);
      }
      //console.log("*** order:", this.curOrder);

      //const assetIds = this.curOrder.assets.map((a) => a.assetId);
  }

  private computeCoef(ratio: number): number {
    if (ratio < 2.5)
      return 1.2;
    if (ratio < 7)
      return 1.2 + 0.2 * (ratio - 2.5) / 4.5;
    if (ratio < 25)
      return 1.4 + 0.6 * (ratio - 7) / 18;
    return 2;
  }

  private doAreaCalculations = (arg: EquipmentDTO): number => {
    arg.area = arg.type === this.equipmentTypes[3] ? (arg.height * arg.height * Math.PI / 4) : (arg.height * arg.width);
    arg.aspectRatio = arg.type === this.equipmentTypes[3] ? 0 : (arg.height / arg.width);
    arg.coefficient = arg.type === this.equipmentTypes[3] ? 1.2617 : this.computeCoef(arg.aspectRatio);
    arg.epaAntenna = arg.area * arg.coefficient;
    return arg.epaAntenna;
  }

  private refreshEpaWA = () => {
    let accumEpaWA = 0;

    this.summaryInfo.forEach(siteSummary => {
      const equipments = this.equipmentsInfo.filter(x => x.assetId == siteSummary.towerId);

      accumEpaWA = 0;
      equipments.forEach(x => accumEpaWA += this.doAreaCalculations(x));
      
      siteSummary.epaWindArea = accumEpaWA;
    });
  }

  // Get a list of sites and their latice measurement info.
  private buildEquipmentsInfo = async (assetIds: number[]) => {
    const tempEquipmentsInfo: Array<EquipmentDTO> = [];    // Alloc a clean equipment array.
    const tempSummaryInfo: Array<WASummary> = []; // Alloc a clean summary info array
    let pushCnt = 0;
    let accumEpaWA = 0;
    this.equipmentsInfo = [];

    // Get tower equipment info.
    const towerEquipments = await this.assetsStore.getTowerEquipments(assetIds);
    // console.log("*** tower equipments:", towerEquipments.assetList); 

    // Get tower info
    const assetInfos = await this.assetsStore.getAssetListInfo(assetIds);

    this.towerNames = towerEquipments.assetList.map((x) => (x.name));
    this.towerId2Names = Object.assign({}, ...towerEquipments.assetList.map(s => ({[s.id]: s.name})));

    // Get equipment types
    if (towerEquipments.assetList.length > 0) {
      const asset = towerEquipments.assetList[0];
      if (asset) {
        this.equipmentTypes = asset.construction.possibleEquipmentTypes;
      }
    }

    towerEquipments.assetList.forEach((asset) => {
      accumEpaWA = 0;

      asset.construction.equipments.forEach((equipment) => {
        // Build equipment array
        const idx = tempEquipmentsInfo.push({
          id: equipment.id,
          assetId: asset.id,
          site: asset.name,
          name: equipment.name,
          type: equipment.type,
          status: EQUIPMENT_STATUS_LABEL[equipment.status],
          operator: equipment.operator,
          face: equipment.face,
          make: equipment.make,
          model: equipment.model,
          elevation: equipment.elevation,
          height: equipment.height,
          width: equipment.width,
          depth: equipment.depth,
          diameter: equipment.height,
          azimuth: equipment.azimuth,
          tilt: equipment.tilt,
          lateralOffset: equipment.lateralOffset,
          standoffDistance: equipment.standoffDistance,
          verticalOffset: equipment.verticalOffset,
          area: 0,
          aspectRatio: 0,
          coefficient: 0,
          epaAntenna: 0
        });
        accumEpaWA += this.doAreaCalculations(tempEquipmentsInfo[idx - 1]);
      });

      // Build site summary array
      const assetInfo = assetInfos.assetList.find(x => x.id == asset.id);
      tempSummaryInfo.push({
        towerId: asset.id,    // Use assetId for now
        site: asset.name,
        region: asset.address.city,
        numberOfOperators: assetInfo ? assetInfo.construction.numberOfTenants : 0,
        latitude: asset.coordinates.lat,
        longitude: asset.coordinates.lon,
        siteType: assetInfo ? assetInfo.construction.siteType : '',
        structureType: assetInfo ? constructionType2name(assetInfo.construction.type) : '',
        buildingHeight: assetInfo ? assetInfo.construction.buildingHeight : 0,
        structureHeight: assetInfo ? assetInfo.construction.height : 0,
        epaWindArea: accumEpaWA,
      });

      pushCnt += 1;

      // Check for all sites being pushed, then re-sort the data and update 'noData' to trigger a rerender.
      if(pushCnt === towerEquipments.assetList.length) {
        this.equipmentsInfo = tempEquipmentsInfo.sort((site1, site2) => (site1.site == site2.site ? 0 : (site1.site > site2.site ? 1 : -1)));
        this.summaryInfo = tempSummaryInfo.sort((site1, site2) => (site1.site == site2.site ? (site1.towerId < site2.towerId ? -1 : 1) : (site1.site < site2.site ? -1 : 1)));
        this.equipmentChanged = false;
        runInAction(() => {
          this._noData = false;
        });
      }
    })
  }

  // Load order and lattice info from portal-api.
  // 
  private loadEquipmentsInfo = async () => {
    try {
      console.log("*** Start getting equipmentsInfo:", this.selectedOrderId, (new Date()).getTime());
      this.setState({ loading: true});

      await this.loadOrder();

      // Get a list of asset ids from this order.
      const assetIds = this.curOrder.assets.map((a) => a.assetId);

      await this.buildEquipmentsInfo(assetIds);

      // Save the selected orderId and filters
      localStorage.setItem(this.mkUserKey(WA_SELECTED_ORDER_ID), this.selectedOrderId.toString());
      localStorage.setItem(this.mkUserKey(WA_ORDER_FILTERS), JSON.stringify(this.filterConfig));
    }
    catch (err) {
      //console.log("*** loadSitesInfo Error:", err);
      showNotification(errObj2Str(err), NotificationType.error);
      runInAction(() => {
        this._noData = true;
      });
    }
    this.setState({ loading: false});
  }

  private refreshEquipments = async () => {
    // Get a list of asset ids from this order.
    const assetIds = this.curOrder.assets.map((a) => a.assetId);
    await this.buildEquipmentsInfo(assetIds);
  }

  // Get list of orders to populate the order selection box.
  private setupOrderSelectionBox = async () => {
    try {
      // Set up queries for calling getList
      const queries: ListRequestParams = {
        pagination: {
          page: 0,
          pageSize: ORDER_MAX_COUNT,
        },
        sorting: {
          field: ORDER_FIELDS.orderName,
          type: SortingType.asc
        }
      };

      // Get order Ids and labels from portal-api.
      await this.ordersStore.getList(queries);
      runInAction(() => {
        this._orders = this.ordersStore.list.length == 0 ? [] : this.ordersStore.list.map(item => ({id: item.id, label: item.orderName}));
      })
      // console.log("**** setup Orders SelectionBox:", this.orders);
    } 
    catch (err) {
      showNotification(errObj2Str(err), NotificationType.error);
      runInAction(() => {
        this._noData = true;
      });
    }
  }

  private getSummaryInfo(): Array<WASummary> {
    return this.summaryInfo;
  }

  private getEquipmentsInfo(): Array<EquipmentDTO> {
    return this.equipmentsInfo;
  }

  private checkEquipmentChanged(reset: boolean): boolean {
    const rc = this.equipmentChanged;
    if (reset) {
      this.equipmentChanged = false;
    }
    return rc;
  }

  // Update tower equipments.
  private async handleUpdateEquipments(args: Array<EquipmentDTO>): Promise<Array<number>> {
    console.log("*** handle onUpdateEquipments:", args);
    // console.log("*** send to api:", args);

    const updateParams: Array<UpdateEquipmentDTO> = [];
    const unchangedIds: Array<number> = [];
    args.forEach(item => {
      const towerId = findKeyByValue(this.towerId2Names, item.site);
      if (towerId) {
        updateParams.push({
          id: item.id,
          towerId: Number(towerId),
          name: item.name,
          typeId: Number(findKeyByValue(this.equipmentTypes, item.type)),
          operator: item.operator,
          make: item.make,
          model: item.model,
          status: Number(findKeyByValue(EQUIPMENT_STATUS_LABEL, item.status)),
          face: item.face,
          elevation: item.elevation,
          height: item.type === this.equipmentTypes[3] ? item.diameter : item.height,
          width: item.type === this.equipmentTypes[3] ? item.diameter : item.width,
          depth: item.depth,
          azimuth: item.azimuth,
          tilt: item.tilt,
          lateralOffset: item.lateralOffset,
          standoffDistance: item.standoffDistance,
          verticalOffset: item.verticalOffset
        });
      }
      else {
        unchangedIds.push(item.id);
      }
    });

    if (updateParams.length > 0) {
      const failedIds = await this.assetsStore.updateTowerEquipments(updateParams);
      
      unchangedIds.push(...failedIds);
    }

    // Do Area/EPA antenna calculations
    if (unchangedIds.length != updateParams.length) {
      // Update only successfully updated equipments
      const updatedIds = updateParams.map(x => x.id).filter(id => unchangedIds.indexOf(id) == -1);
      
      updatedIds.forEach(id => {
        const newItem = args.find(x => x.id == id);

        // Update item equipmentsInfo
        const item = this.equipmentsInfo.find(x => x.id == id);
        if (item && newItem) {
          Object.assign(item, newItem);
          this.doAreaCalculations(item);
        }
      });

      // Refresh site's EPA-WindArea
      this.refreshEpaWA();
      this.equipmentChanged = true;
    }
    
    // Show notifications
    if(unchangedIds.length == 0) {
      showNotification(`${args.length} row(s) updated.`, NotificationType.info);
    }
    else {
      showNotification(`${args.length - unchangedIds.length} row(s) updated.`, NotificationType.error);
    }

    return unchangedIds;
  }

  private async handleAddEquipment(args: EquipmentDTO): Promise<number> {
    console.log("*** handle onAddEquipment:", args);
    // Add a tower equipment.
    // console.log("*** send to api:", args);
    let newId = 0;
    const towerId = findKeyByValue(this.towerId2Names, args.site);
    if (towerId) {
      args.assetId = Number(towerId);
      newId = await this.assetsStore.addTowerEquipment({
        id: 0,
        towerId: Number(towerId),
        name: args.name,
        typeId: Number(findKeyByValue(this.equipmentTypes, args.type)),
        operator: args.operator,
        make: args.make,
        model: args.model,
        status: Number(findKeyByValue(EQUIPMENT_STATUS_LABEL, args.status)),
        face: args.face,
        elevation: args.elevation,
        height: args.type === this.equipmentTypes[3] ? args.diameter : args.height,
        width: args.type === this.equipmentTypes[3] ? args.diameter : args.width,
        depth: args.depth,
        azimuth: args.azimuth,
        tilt: args.tilt,
        lateralOffset: args.lateralOffset,
        standoffDistance: args.standoffDistance,
        verticalOffset: args.verticalOffset
      });      
    }

    if(newId !== 0) {
      args.id = newId;
      this.doAreaCalculations(args);
      this.equipmentsInfo.push({...args});

      // Update EPA Wind Area
      this.refreshEpaWA();
      this.equipmentChanged = true;

      // Show notifications
      showNotification(`1 equipment added. Other properties of this equipment can be edited in other view mode.`, NotificationType.info);
    }
    else {
      showNotification(`Failed to add a new equipment!`, NotificationType.error);
    }

    return newId;
  }

  private async handleDeleteEquipments(args: Array<number>): Promise<Array<number>> {
    console.log("*** handle onDeleteEquipments:", args);
    // Delete a tower equipment.
    // console.log("*** send to api:", args);
    const deletedIds = await this.assetsStore.deleteTowerEquipments(args);
    
    if(deletedIds.length !== 0) {
      // Delete items in equipmentsInfo
      deletedIds.forEach(id => {
        const idx = this.equipmentsInfo.findIndex(x => x.id == id);
        if (idx !== -1) {
          this.equipmentsInfo.splice(idx, 1);
        }
      });

      // Update EPA Wind Area
      this.refreshEpaWA();
      this.equipmentChanged = true;

      // Show notifications
      showNotification(`${deletedIds.length} equipment(s) deleted.`, NotificationType.info);
    }
    else {
      showNotification(`Failed to delete the equipment(s)!`, NotificationType.error);
    }
    return deletedIds;
  }

  //
  render() {
    const { setHeaderConfig } = this.props;
    const classes = withStyles.getClasses(this.props);

    // Show 'noData'
    if(this.noData) {

      // Message if there are no filters.
      let noDataMsg = "No records found.  Please check if the order has been populated.";

      for (const filterItem of this.filterConfig) {
        if(filterItem.ids != undefined && filterItem.ids.length > 0) {
          // Message if there is at least one filter.
          noDataMsg = "No records found.  Please check your permissions and filter configuration.";
          break;
        }
      }
  
      return (
        <div>
          <Flex 
            className={classes.root} 
            direction="column"
          >
            {this.showFlex()}
            <Flex className={classes.pageMsg}>{noDataMsg}</Flex>
          </Flex>
        </div>   
      )
    }

    // Show spinner (loading).
    if(this.selectedOrderId != -1 && this.state.loading) { // Note: where state.loading defined???
      return (
        <Loading absolute />
      )
    }

    console.log(`****** render Wind Area ${new Date()}.`);

    // Show sub components.
    return (
      <div>        
        <Flex 
          className={classes.root} 
          direction="column"
        >
          <Flex // Show the top row: order selection box, Filters dropdown, and Refresh link.
            container
          >
            {this.fields.map((field, idx) => (
              <Grid key={`grid_${field.id}`}><Field classes={ idx == 0 ? { root: classes.selectOrder } : { } } {...field} key={field.id} /></Grid>))
            }
          </Flex>

          {this.summaryInfo.length > 0 && (  // Show the worksheet components.
            <Flex 
              direction="row"
            >
              <RefreshFlagContext.Consumer>
              {() => (
                <Sheets
                  setHeaderConfig={setHeaderConfig} 
                  name={`${this.selectedOrderId}`}
                  summaryInfo={this.getSummaryInfo.bind(this)}
                  equipmentsInfo={this.getEquipmentsInfo.bind(this)}
                  checkEquipmentChanged={this.checkEquipmentChanged.bind(this)}
                  equipmentTypes={this.equipmentTypes}
                  towerNames={this.towerNames}
                  onUpdateEquipments={this.handleUpdateEquipments.bind(this)}
                  onAddEquipment={this.handleAddEquipment.bind(this)}
                  onDeleteEquipments={this.handleDeleteEquipments.bind(this)}
                />
              )}
              </RefreshFlagContext.Consumer>
            </Flex>
          )}

        </Flex>
      </div>
    );
  }
}

const WindAreaStyled = withStyles(
  WindArea, 
  (theme, props) => ({
    "root": {
      paddingTop: 10,
      paddingLeft: 20,
      width: '100%',
      flexWrap: 'nowrap',   // show horizontal scroll bar
      // overflowX: 'scroll',  // if width too small to accommodate both map and details.
    },
    "selectOrder": {
      paddingLeft: theme.spacing(2),
      width: 280,
    },
    "selectBox": {
      width: 200,           // Kludgy.  The selectBox extends beyond its bounds so it appears closer to the filter button.
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(1)
    },
    "refreshButton": {
      fontWeight: 'normal',
      color: '#0870d8',
      '&:hover': {
          backgroundColor: '#daedff80'
      },
    },
    "svgIconDone": {
      fontSize: 20,
      marginRight: theme.spacing(2),
      color: theme.palette.colors.green,
    },
    "pageMsg": {
      paddingTop: 25,
    }
  })
);

export default WindAreaStyled;